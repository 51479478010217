import { Instance, types } from 'mobx-state-tree'
import { createStorable } from '../helpers/storable'
import * as routes from '../routes'
import _ from 'lodash'
import Moment from '../types/Moment'
import { ApplicationType } from '../dictionaries/applicationType'

export const Item = types
  .model('Item', {
    identifier: '',
    title: '',
    filled: false,
    content: types.optional(types.frozen(), {}),
  })
  .actions((self) => ({
    setContent: (value: any) => {
      self.content = value
    },
    setFilled: (value: boolean) => {
      self.filled = value
    },
    setTitle: (value: string) => {
      self.title = value
    },
  }))

export const Group = types
  .model('Group', {
    group: '',
    title: '',
    items: types.array(Item),
  })
  .actions((self) => ({
    insertAt(index: number, data: Instance<typeof Item>) {
      self.items.splice(index, 0, data)
    },
    replaceItems(value: any) {
      self.items = value
    },
    setTitle(value: string) {
      self.title = value
    },
  }))
  .views((self) => ({
    get allFilled() {
      return self.items.length > 0 && self.items.every((item) => item.filled)
    },
    get(identifier: string, raw = false) {
      const item = self.items.find((i) => i.identifier === identifier)
      if (raw) return item
      if (!item) return null
      return item.content
    },
  }))

export const PrequalData = types.model('PrequalData', {
  approvedAmount: 0,
  validTill: '',
})

export const VirtualCardDetails = types.model('VirtualCardDetails', {
  unused: false,
  cardExpiryDate: '',
})

export const Document = types
  .model({
    sub: '',
    company_id: '',
    type: types.optional(
      types.enumeration([
        'general_application',
        ApplicationType.Credit,
        ApplicationType.Supplier,
        ApplicationType.InHouseCredit,
        'project_form',
      ]),
      'project_form',
    ),
    firstQuestion: '',
    data: types.map(Group),
    current: '',
    filled: types.array(types.string),
    invoice_id: types.maybeNull(
      types.union(types.string, types.array(types.string)),
    ),
    isSentBack: types.optional(types.boolean, false),
    loanApplicationId: '',
    loanStatus: '',
    prequalData: types.maybeNull(PrequalData),
    virtualCardDetails: types.maybeNull(VirtualCardDetails),
    submitDate: types.maybe(Moment),
    version: types.optional(types.number, 1),
  })
  .views((self) => ({
    get currentData() {
      if (!self.current) return null
      const [group, id] = self.current.split('.')
      const g = self.data.get(group)
      if (!g || !g.items) return null
      return g.items.find((d) => d.identifier === id) || null
    },
    get(group: string, identifier: string) {
      return self.data.get(group)?.get(identifier)
    },
    exists(group: string, identifier: string) {
      const g = self.data.get(group)
      if (!g) return false
      const i = g.get(identifier, true)
      return !!i
    },
    isFilled(
      group: string,
      identifier: string,
      validator?: (arg: any) => boolean,
    ) {
      const item = self.data
        ?.get(group)
        ?.items?.find((i) => i.identifier === identifier)
      return validator ? validator(item?.content) : item?.filled
    },
  }))
  .actions((self) => ({
    moveBack() {
      let popped = self.filled.pop()
      while (
        (['finance.debt', 'finance.howMuchCredit'].includes(popped || '') &&
          self.type === 'supplier_application') ||
        self.current === popped
      ) {
        popped = self.filled.pop()
      }
      self.current = popped || self.current
    },
    startOver(initial: string) {
      self.filled.clear()
      self.current = initial
    },
    setCurrent(current: string) {
      if (!self.filled.slice().includes(self.current)) {
        self.filled.push(self.current)
      }
      self.current = current
      if (!current || current === 'review') return
      if (!self.currentData) {
        const [g, i] = self.current.split('.')
        this.setData(i, '', '', g)
      }
    },
    setFirstQuestion(firstQuestion: string) {
      self.firstQuestion = firstQuestion
    },
    setSection(section: string, onEdit = true) {
      self.filled.clear()
      if (!self.filled.slice().includes(self.current) && onEdit) {
        self.filled.push(self.current)
      }
      self.current = section
    },
    setContent(
      group: string,
      identifier: string,
      value: any,
      filled?: boolean,
    ) {
      this.setData(identifier, value, '', group, '', filled)
    },
    setData(
      identifier: string,
      value: any,
      title: string,
      group: string,
      groupTitle?: string,
      filled?: boolean,
    ) {
      const calcFilled = () => {
        if (_.isBoolean(value)) return true
        if (_.isObject(value)) {
          if (_.isEmpty(value)) return false
          const checkValue = (v: any) => !_.isEmpty(v) || _.isBoolean(v)
          return Object.values(value).every((v) => {
            if (_.isArray(v)) {
              return v.every((vv) => {
                if (_.isObject(vv)) {
                  return Object.values(vv).every((vvv) => checkValue(vvv))
                }
                return checkValue(vv)
              })
            }
            return checkValue(v)
          })
        }
        return !_.isEmpty(value)
      }

      const item = self.data.get(group)
      if (item) {
        if (groupTitle) item.setTitle(groupTitle)
        const row = item.items.find((i) => i.identifier === identifier)
        if (row) {
          row.setContent(value)
          row.setFilled(!_.isUndefined(filled) ? filled : calcFilled())
          row.setTitle(title || row.title)
        } else {
          item.items.push({
            identifier,
            title,
            content: value,
            filled: !_.isUndefined(filled) ? filled : calcFilled(),
          })
        }
      } else {
        self.data.set(group, {
          group,
          title: groupTitle,
          items: [
            Item.create({
              title,
              identifier,
              content: value,
              filled: !_.isUndefined(filled) ? filled : calcFilled(),
            }),
          ],
        })
      }
    },
    removeData(group: string, identifier: string) {
      const g = self.data.get(group)
      if (!g) return
      const items = g.items.filter((i) => i.identifier !== identifier)
      g.items.replace(items)
      if (g.items.length === 0) self.data.delete(group)
    },
    clearItems(identifiers: string[]) {
      this.clearData((item, index, groupName) => {
        return !identifiers.includes(`${groupName}.${item.identifier}`)
      })
    },
    clearData(
      filter: (
        element: Instance<typeof Item>,
        index: number,
        key: string,
        keyIndex: number,
      ) => boolean,
    ) {
      Array.from(self.data.keys()).forEach((k, ind) => {
        const group = self.data.get(k)
        if (!group) return
        const items = group.items.filter((i, ix) => filter(i, ix, k, ind))
        if (items?.length === 0) {
          self.data.delete(k)
        } else {
          group.replaceItems(items)
        }
      })
    },
    insertAt(index: number, key: string, value: Instance<typeof Group>) {
      const arr = Array.from(self.data.entries())
      arr.splice(index, 0, [key, value])
      self.data.replace(new Map(arr))
    },
  }))
  .named('Document')
export const Draft = types
  .compose(Document, createStorable(routes.user.saveDraft, routes.user.draft))
  .named('Draft')
export const AdminDraft = types
  .compose(
    Document,
    createStorable(routes.admin.supplierApplicationEdit, routes.user.draft),
  )
  .named('AdminDraft')

export interface IDraftModel extends Instance<typeof Draft> {}
