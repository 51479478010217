import { get, post } from '../helpers/caller'
import { IDownloadable } from '../types/approvals'

export const approvals = {
  domain: 'approvals',

  // TODO: VK: Rename to downloadMasterAgreement
  downloadMasterAgreement(id: string, jwt: string) {
    return get(approvals.domain, approvals.downloadMasterAgreement, {
      id,
      jwt,
    })
  },

  downloadPersonalGuarantorAgreement(ownerId: string, jwt: string) {
    return get(approvals.domain, approvals.downloadPersonalGuarantorAgreement, {
      ownerId,
      jwt,
    })
  },

  downloadGuarantySecuredAgreement(
    ownerId: string,
    jwt: string,
  ): Promise<IDownloadable> {
    return get(approvals.domain, approvals.downloadGuarantySecuredAgreement, {
      ownerId,
      jwt,
    })
  },

  downloadSellerAgreement(
    companyId: string,
    jwt: string,
  ): Promise<IDownloadable> {
    return get(approvals.domain, approvals.downloadSellerAgreement, {
      companyId,
      jwt,
    })
  },

  downloadInvoicePurchaseAgreement(
    companyId: string,
    jwt: string,
  ): Promise<IDownloadable> {
    return get(approvals.domain, approvals.downloadInvoicePurchaseAgreement, {
      companyId,
      jwt,
    })
  },

  approveCreditAgreements(jwt: string, ipAddress: string) {
    return post(approvals.domain, approvals.approveCreditAgreements, {
      jwt,
      ipAddress,
    })
  },

  approveGetPaidAgreements(jwt: string, ipAddress: string) {
    return post(approvals.domain, approvals.approveGetPaidAgreements, {
      jwt,
      ipAddress,
    })
  },
}
