export const IntegrationDotNetErrorCodes = {
  invoice_token_expired: 'invoice_token_expired',
  invoice_token_format_invalid: 'invoice_token_format_invalid',
  invoice_token_invalid: 'invoice_token_invalid',
  invalid_data: 'invalid_data',
  invoice_with_external_id_already_exist:
    'invoice_with_external_id_already_exist',
  integration_does_not_exist: 'integration_does_not_exist',
} as const

export const unexpectedErrorCode = 'unexpected_error' as const

export const IntegrationRedirectStatuses = {
  InReview: 'InReview', // BTC
  PaymentProcessing: 'PaymentProcessing', //ACH&card
  InactivePaymentLink: 'InactivePaymentLink',
  InvalidData: 'InvalidData',
  SomethingWentWrong: 'SomethingWentWrong',
  NoActionsNeeded: 'NoActionsNeeded',
} as const

export type IntegrationRedirectStatusesType =
  keyof typeof IntegrationRedirectStatuses

export type IntegrationDotNetErrorCodesType =
  keyof typeof IntegrationDotNetErrorCodes

export type IntegrationErrorCodesType =
  | typeof IntegrationDotNetErrorCodes[IntegrationDotNetErrorCodesType]
  | typeof unexpectedErrorCode

export const ErrorCodeToStatusMapping: Record<
  IntegrationErrorCodesType,
  typeof IntegrationRedirectStatuses[IntegrationRedirectStatusesType]
> = {
  [IntegrationDotNetErrorCodes.invoice_token_expired]:
    IntegrationRedirectStatuses.InactivePaymentLink,
  [IntegrationDotNetErrorCodes.invoice_token_format_invalid]:
    IntegrationRedirectStatuses.SomethingWentWrong,
  [IntegrationDotNetErrorCodes.invoice_token_invalid]:
    IntegrationRedirectStatuses.SomethingWentWrong,
  [IntegrationDotNetErrorCodes.invalid_data]:
    IntegrationRedirectStatuses.InvalidData,
  [IntegrationDotNetErrorCodes.invoice_with_external_id_already_exist]:
    IntegrationRedirectStatuses.InvalidData,
  [IntegrationDotNetErrorCodes.integration_does_not_exist]:
    IntegrationRedirectStatuses.InvalidData,
  [unexpectedErrorCode]: IntegrationRedirectStatuses.SomethingWentWrong,
} as const

export interface IInvoiceCompanyInfo {
  id: string
  name: string
  phone: string
  bnplPaymentAvailable: boolean
  cardPaymentAvailable: boolean
  acceptAchPayment: boolean
}

export interface IPayloadData {
  companyId: string
  totalAmount: number
  address: string
  lines: {
    name: string
    description: string
    unitAmount: number
    quantity: number
    subTotal: number
    taxAmount: number
    totalAmount: number
  }[]

  invoiceNumber: string
  connector: {
    externalInvoiceId: string
    externalCustomerId: string
    integrationId: string
    projectDetails?: {
      projectId: string
      name: string
      address: string
      contractValue: number
      startDate: Date
      endDate: Date
      jobId: string
      jobAddress: {
        address: string
        unitNo: string
        state: string
        zipCode: string
        city: string
        lotNumber: string
        blockNumber: string
        s3FileUrl: string
      }
      role: string
      primeContractorDetails: {
        businessName: string
        firstName: string
        lastName: string
        businessPhoneNumber: string
        businessEmail: string
        businessAddress: string
        state: string
        city: string
        zipCode: string
      }
      type: string
      privateProjectDetails: {
        privateProjectType: string
        builtFor: {
          buildForType: string
          notes: string
        }
      }
      publicProjectDetails: {
        description: string
        hasBond: boolean
        isBondRequired: boolean
        s3FileUrl: string
      }
      federalProjectDetails: {
        hasBond: boolean
        s3FileUrl: string
      }
      individualOwners: [
        {
          firstName: string
          lastName: string
          phone: string
          homeAddress: string
          state: string
          city: string
          zipCode: string
        },
      ]
      businessOwners: [
        {
          businessName: string
          firstName: string
          lastName: string
          businessPhoneNumber: string
          businessAddress: string
          state: string
          city: string
          zipCode: string
        },
      ]
    }
  }
}

export interface IInvoiceDataFromJwt {
  payload: IPayloadData
  invoiceId: string
}

export interface IInvoiceJwtHealthCheckResponse {
  errorCode: string | null
  data: IInvoiceDataFromJwt | null
}

export interface IInvoiceJwtUpsertResponse {
  invoiceId: string | null
  errorCode: IntegrationErrorCodesType | null
}

export interface ICheckInvoiceIsPaid {
  status: string | null
  isPaid: boolean
}
