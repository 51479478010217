import { CreateCardRequest, CreateCardResponse, SupplierAccount } from '../dtos'
import { delete_, get, post } from '../helpers/caller'
import { IBankAccountModel } from '../mst'
import { IUserRequest } from './supplierTypes'
import { IArAdvanceCreditInfo } from '../interfaces/IArAdvanceCreditInfo'

export const supplier = {
  domain: 'supplier',
  acceptAgreement() {
    return post(supplier.domain, supplier.acceptAgreement)
  },
  account(id: string) {
    return get(supplier.domain, supplier.account, { id })
  },
  customerDetails(id: string) {
    return get(supplier.domain, supplier.customerDetails, { id })
  },
  customerAdditionalInfo(id: string) {
    return get(supplier.domain, supplier.customerAdditionalInfo, { id })
  },
  allAccounts(params: {
    search?: string
    sortColumn?: string
    sortDirection?: string
    page?: number
    pageSize?: number
  }) {
    return get<{
      items: SupplierAccount[]
      count: number
    }>(supplier.domain, supplier.account, { ...params })
  },
  allCustomersOfSupplier(params: {
    search?: string
    sortColumn?: string
    sortDirection?: string
    page?: number
    pageSize?: number
  }) {
    return get<{
      items: SupplierAccount[]
      totalCount: number
    }>(supplier.domain, supplier.allCustomersOfSupplier, { ...params })
  },
  user(params: IUserRequest) {
    return get(supplier.domain, supplier.user, { ...params })
  },
  saveAccount(account: any, sendInvite: boolean) {
    return post(supplier.domain, supplier.account, { account, sendInvite })
  },
  accountsImport(items: any, invite = false) {
    return post(supplier.domain, supplier.accountsImport, { items, invite })
  },
  employee(id: string) {
    return get(supplier.domain, supplier.employee, { id })
  },
  employeeUpsert(user: any) {
    return post(supplier.domain, supplier.employee, user)
  },
  invitation(id: string) {
    return get(supplier.domain, supplier.invitation, { id })
  },
  invitationSubmit(data: any) {
    return post(supplier.domain, supplier.invitation, data)
  },
  check() {
    return get(supplier.domain, supplier.check)
  },
  isAgreementActual() {
    return get(supplier.domain, supplier.isAgreementActual)
  },
  checkExistingCustomer(phone: string, email: string, id: string) {
    return post(supplier.domain, supplier.checkExistingCustomer, {
      phone,
      email,
      id,
    })
  },
  billingContact(customerAccountId: string) {
    return get(supplier.domain, supplier.billingContact, {
      customerAccountId,
    })
  },
  saveBillingContact(
    customerAccountId: string,
    contacts: { email?: string; phone?: string }[],
  ) {
    console.log(customerAccountId, 'akshay', contacts)
    return post(supplier.domain, supplier.billingContact, {
      customerAccountId,
      contacts,
    })
  },
  deleteAccount(id: string) {
    return delete_(supplier.domain, supplier.account, { id })
  },
  deleteContact(id: string) {
    return delete_(supplier.domain, supplier.billingContact, { id })
  },
  customerLoans(customerAccountId: string) {
    return get(supplier.domain, supplier.customerLoans, {
      customerAccountId,
    })
  },
  creditInfo(customerCompanyId: string, customerId: string) {
    return get(supplier.domain, supplier.creditInfo, {
      customerCompanyId,
      customerId,
    })
  },

  getArAdvanceCreditInfo(): Promise<{ creditInfo: IArAdvanceCreditInfo }> {
    return get(supplier.domain, supplier.getArAdvanceCreditInfo)
  },

  bankAccounts(customerAccountId: string, bankAccountId: string) {
    return get<{ bankAccount: IBankAccountModel }>(
      supplier.domain,
      supplier.bankAccounts,
      {
        customerId: customerAccountId,
        id: bankAccountId,
      },
    )
  },
  addBankAccount(customerAccountId: string, bankAccount: any) {
    return post<{ id: string }>(
      supplier.domain,
      supplier.bankAccounts,
      bankAccount,
      {
        id: customerAccountId,
      },
    )
  },
  deleteBankAccount(customerAccountId: string, bankAccountId: string) {
    return delete_(supplier.domain, supplier.bankAccounts, {
      customerId: customerAccountId,
      id: bankAccountId,
    })
  },
  paymentCard(customerAccountId: string, data: CreateCardRequest) {
    return post<CreateCardResponse>(
      supplier.domain,
      supplier.paymentCard,
      data,
      { customerId: customerAccountId },
      true,
    )
  },
  deletePaymentCard(customerAccountId: string, paymentCardId: string) {
    return delete_<CreateCardResponse>(
      supplier.domain,
      supplier.paymentCard,
      {
        customerId: customerAccountId,
        id: paymentCardId,
      },
      true,
    )
  },

  previewSellerAgreement() {
    return get(supplier.domain, supplier.previewSellerAgreement)
  },

  downloadSellerAgreement() {
    return get(supplier.domain, supplier.downloadSellerAgreement)
  },

  previewInvoicePurchaseAgreement(draftId: string) {
    return get(supplier.domain, supplier.previewInvoicePurchaseAgreement, {
      draftId,
    })
  },

  downloadInvoicePurchaseAgreement() {
    return get(supplier.domain, supplier.downloadInvoicePurchaseAgreement)
  },

  previewGuarantySecuredAgreement(draftId: string) {
    return get(supplier.domain, supplier.previewGuarantySecuredAgreement, {
      draftId,
    })
  },
}
