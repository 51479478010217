export const bankAccountStatuses = [
  'verified',
  'notverified',
  'manualverified',
] as const

export type BankAccountStatuses = typeof bankAccountStatuses[number]

export const bankAccountPaymentMethodTypes = ['bank', 'card'] as const
export type BankAccountPaymentMethodTypes =
  typeof bankAccountPaymentMethodTypes[number]

export enum BankAccountPaymentMethods {
  Bank = 'bank',
  Card = 'card',
}

export const BankAccountType = {
  SAVINGS: 'savings',
  CHECKING: 'checking',
}

export type AccountType = typeof BankAccountType[keyof typeof BankAccountType]

export const BankConnectionType = {
  PLAID: 'plaid',
  FINICITY: 'finicity',
} as const

export type ConnectionType =
  typeof BankConnectionType[keyof typeof BankConnectionType]

export const PlaidStatus = {
  EXPIRED: 'expired',
  DISCONNECTED: 'disconnected',
  ACTIVE: 'active',
}
