import { types } from 'mobx-state-tree'
import {
  ACHdiscount,
  Company,
  CompanyStatus,
  Draft,
  routes,
  routes2,
  SupplierInfo,
  User,
  UserRole,
} from '@linqpal/models'
import fb from '../utils/service/fb'
import * as Sentry from '@sentry/react'
import cookies from '../utils/service/cooker'
import ApplicationStore from '../screens/GeneralApplication/Application/ApplicationStore'
import { PURCHASE_TYPE } from '@linqpal/models/src/dictionaries'
import { ErpSystems } from '@linqpal/models/src/dictionaries/ErpSystems'
import { ApplicationType } from '@linqpal/models/src/dictionaries/applicationType'
import RootStore from './index'
import { dispatcher, pathFactory, paths } from '../screens/links'
import LocalStorage from './LocalStorage'

let authStateChangedUnsubscribe

export default types
  .model('UserStore', {
    id: types.optional(types.identifier, 'UserStore'),
    user: types.maybeNull(User),
    settings: types.map(
      types.union(
        types.boolean,
        types.string,
        types.array(types.string),
        types.number,
        ACHdiscount,
      ),
    ),
    company: types.maybeNull(Company),
    businessCategory: types.optional(types.array(types.string), []),
    paymentMethodAdded: types.optional(types.boolean, false),
    invoicesDuetoPay: types.optional(types.boolean, false),
    document: types.maybeNull(Draft),
    roles: types.array(UserRole),
    role: types.maybe(UserRole),
    isAuthenticated: types.optional(types.boolean, false),
    hasActualAgreement: types.optional(types.boolean, true),
    isCustomerOfSupplier: types.optional(types.boolean, false),
    brandingInfo: types.maybeNull(types.frozen()),
    projectAdded: types.optional(types.boolean, false),
    connectedServices: types.map(types.maybeNull(types.boolean)),
    credit: types.maybeNull(
      types.model({
        LoCnumber: types.optional(types.string, ''),
        purchaseType: types.maybeNull(
          types.enumeration(Object.values(PURCHASE_TYPE)),
        ),
      }),
    ),
    suppliers: types.array(SupplierInfo),
    inHouseCreditApplications: types.array(types.frozen()),
  })
  .volatile(() => ({ userInfoReady: false }))
  .views((self) => ({
    get supplierCanPay() {
      return self.settings?.get('supplierCanPay')
    },
    isNewLoCAgreementAvailable() {
      if (!self.credit?.LoCnumber) {
        return false // very first submission - nothing to update
      }
      return routes.contractor
        .getAgreementMetadata('master')
        .then((response) => {
          return (
            !!response?.currentVersion && response?.hasLatestVersion === false
          )
        })
    },
    get isPurchaseForProject() {
      if (self.credit?.purchaseType)
        return self.credit?.purchaseType === PURCHASE_TYPE.PROJECT
      else return false
    },
    get shouldSelectPurchaseType() {
      return self.credit?.purchaseType === PURCHASE_TYPE.PROJECT_OR_INVENTORY
    },
    get useArAdvance() {
      return self.company?.settings?.arAdvance?.isEnabled
    },
  }))
  .actions((self) => ({
    setInfo(info) {
      if (info) {
        self.roles.replace(info.roles)
        self.suppliers.replace(info.suppliers)
        self.inHouseCreditApplications.replace(info.inHouseCreditApplications)
        if (info.company?.businessCategory) {
          self.businessCategory = Array.isArray(info.company.businessCategory)
            ? info.company.businessCategory
            : [info.company.businessCategory]
        }
        self.isCustomerOfSupplier = info.company?.isCustomerOfSupplier
        self.brandingInfo = info.brandingInfo
        self.projectAdded = info.projectAdded
        self.user = info.user
        Sentry.setUser({ username: info.user?.login })
        self.company = info.company
        self.credit = info.company?.credit
        if (info.company?.bankAccounts?.length > 0) {
          self.paymentMethodAdded = true
        }
        self.settings.replace(info.settings || {})
        self.connectedServices.replace(info.connectedServices || {})
      } else {
        Sentry.configureScope((scope) => scope.setUser(null))
        self.user = null
        self.roles.clear()
        self.company = null
        self.document = null
        self.suppliers.clear()
        self.inHouseCreditApplications.clear()
        self.paymentMethodAdded = false
        self.invoicesDuetoPay = false
        self.isCustomerOfSupplier = false
        self.settings.clear()
        self.connectedServices.clear()
      }
      self.userInfoReady = !!info
    },
    setIsAuthenticated(isAuthenticated: boolean) {
      self.isAuthenticated = isAuthenticated
    },
    setDocument(doc) {
      try {
        self.document = Draft.create(doc)
      } catch (e) {
        self.document = Draft.create({})
      }
    },
    async fetchUser(setUserInfo = true) {
      if (!cookies.get('session')) return
      const request = routes2.user.info()
      request
        .then((data) => {
          if (setUserInfo) {
            this.setInfo(data)
          }
        })
        .catch((e) => {
          if (e.code === 'auth/user-not-found') {
            this.logOut()
          }
          console.log('error', e)
          this.setInfo(null)
        })
      return request
    },
    setinvoicesDuetoPay(value) {
      self.invoicesDuetoPay = value
    },
    setHasActualAgreement(value: boolean) {
      self.hasActualAgreement = value
    },
    async checkInvoicesToPay() {
      const response = await routes.company.checkInvoicesToPay()
      if (response?.result === 'ok') {
        this.setinvoicesDuetoPay(!!response?.invoicesDuetoPay)
      }
    },
    setErpSystemConnected(service: ErpSystems, connected: boolean) {
      self.connectedServices[service] = connected
    },
    async isErpSystemConnected(service: ErpSystems) {
      let connected = self.connectedServices[service]
      if (connected == null) {
        switch (service) {
          case ErpSystems.QuickBooks:
            const response = await routes.quickBooks.isConnected()
            connected = response.result
            break
          default:
            throw new Error(`Unsupported ERP service ${service}`)
        }
        self.connectedServices[service] = connected
      }
      return connected
    },
    fetchDocument(type: ApplicationType) {
      const initial = ApplicationStore.firstQuestion
      const request = routes.user.draft(type, initial)
      request.then(this.setDocument)
      return request
    },
    async logOut() {
      console.log('Log out, clearing session')
      await routes.user.logout()
      this.setAuthState(null)
      cookies.remove('session')
      await fb.auth().signOut()
    },
    setLocalSettings: (setting) => {
      console.log('setLocaleSettings', self.settings, setting)
      self.settings.merge(setting)
    },
    afterCreate() {
      //Wait for axios base configuration to finish
      setTimeout(() => {
        this.setAuthState(cookies.get('session'))
      }, 50)
    },
    beforeDestroy() {
      authStateChangedUnsubscribe && authStateChangedUnsubscribe()
    },
    redirectFromLoginPage(navigation: any) {
      if (RootStore.routeParams.loanid) {
        navigation.navigate(paths.Console.Credit.LoanDetails, {
          id: RootStore.routeParams.loanid,
        })
      }
      LocalStorage.get('screen').then((screen) => {
        if (screen) {
          LocalStorage.remove('screen')
          const action = dispatcher(pathFactory(screen.screen), screen.params)
          navigation.replace(action.name, action.params)
        }
      })
    },
    async setAuthState(user) {
      self.isAuthenticated = !!user

      if (self.isAuthenticated) {
        const userInfo = await this.fetchUser(false)

        // check if supplier has actual agreement version (for non-AR Advance suppliers only)
        // has to be called after fetchUser, but before setting userInfoReady
        // to prevent displaying home page before agreement acceptance
        if (
          userInfo?.company?.status === CompanyStatus.Approved &&
          userInfo.company.settings?.arAdvance?.isEnabled !== true
        ) {
          const hasActualAgreement = await routes.supplier.isAgreementActual()
          this.setHasActualAgreement(hasActualAgreement.result)
        } else {
          this.setHasActualAgreement(true)
        }

        this.setInfo(userInfo)
        return userInfo
      } else {
        this.setInfo(null)
        return undefined
      }
    },
    updateInHouseCreditApplicationStatus(
      companyId: string,
      merchantId: string,
      newStatus: string,
    ) {
      // workaround for DE async behavior:
      // DE sets PROCESSING status for sent back application asynchronously
      // so right after submission we cannot detect if application is processing or not
      // so force set PROCESSING status on UI to hide Submit IHC Form button from home page

      const updatedApps = self.inHouseCreditApplications.map((app: any) =>
        app.companyId === companyId && app.merchantId === merchantId
          ? { ...app, status: newStatus }
          : app,
      )

      self.inHouseCreditApplications.replace(updatedApps)
    },
  }))
