import {
  IInvoiceCompanyInfo,
  IInvoiceJwtHealthCheckResponse,
  IInvoiceJwtUpsertResponse,
} from '../dictionaries'
import { get, post } from '../helpers/caller'

const domain = 'integration'
export const integration = {
  getInvitationId(customerId: string) {
    return get(domain, integration.getInvitationId, { customerId })
  },
  upsertInvoiceFromJwt(jwtToken: string): Promise<IInvoiceJwtUpsertResponse> {
    return post(domain, integration.upsertInvoiceFromJwt, { jwtToken })
  },
  checkAndGetInvoiceFromJwt(
    jwtToken: string,
  ): Promise<IInvoiceJwtHealthCheckResponse> {
    return get(domain, integration.checkAndGetInvoiceFromJwt, { jwtToken })
  },
  getInvoiceCompanyInfo(companyId: string): Promise<IInvoiceCompanyInfo> {
    return get(domain, integration.getInvoiceCompanyInfo, { companyId })
  },
}
