import {
  onSnapshot,
  types,
  getSnapshot,
  getType,
  IDisposer,
  IAnyStateTreeNode,
  applySnapshot,
} from 'mobx-state-tree'
import { debounce } from 'lodash'
import { ApplicationType } from '../dictionaries/applicationType'
import { CancellablePromise } from './caller'
import { runInAction } from 'mobx'

const createLogger = (rootScope: string, self: IAnyStateTreeNode) => ({
  log: (scope: string, ...args: any[]) =>
    console.log(
      `${rootScope}:${scope} /${getType(self).name} [${self.id}]`,
      ...args,
    ),
})

const storableSave = (
  self: any,
  saver: (d: any) => Promise<any>,
  logger: ReturnType<typeof createLogger>,
) => {
  return debounce(
    async () => {
      if (self.suppressSave) {
        logger.log('storableSave:skipped', 'Save suppressed by flag')
        return
      }

      try {
        self.toggleSuppressSave(true)

        self.setVersion((self.version || 0) + 1)
        const snapshot = getSnapshot(self) as Record<string, any>

        const ret = await saver(snapshot)

        runInAction(() => {
          if (ret && ret._id && self._id !== ret._id) {
            self.setId(ret._id)
          }
        })
      } catch (e: any) {
        if (e?.code === 'version-mismatch') {
          logger.log('storableSave:version-mismatch', e.message)
          await self.fetchDocument(self?.type)
        } else {
          logger.log('storableSave:failed', e)
        }
      } finally {
        self.toggleSuppressSave(false)
      }
    },
    1000,
    {
      leading: false,
      trailing: true,
    },
  )
}

export function createStorable(
  saveRoute: (data: any) => Promise<any>,
  fetchRoute: (
    documentType: ApplicationType,
    current: string | undefined,
  ) => CancellablePromise<any>,
) {
  const actions = (self: any) => {
    let snapshotDisposer: IDisposer | undefined
    const logger = createLogger(`storable:${saveRoute}`, self)
    return {
      storableSave: storableSave(self, saveRoute, logger),
      afterCreate() {
        snapshotDisposer && snapshotDisposer()
        snapshotDisposer = onSnapshot(self, () => {
          if (!self.suppressSave) {
            self.storableSave()
          }
        })
      },
      beforeDestroy() {
        snapshotDisposer && snapshotDisposer()
        snapshotDisposer = undefined
      },
      setId(id: string) {
        self._id = id
      },
      setVersion(version: number) {
        self.version = version
      },

      toggleSuppressSave(state: boolean) {
        self.suppressSave = state
      },
      toggleSuppressSnapshot(state: boolean) {
        console.log(`Toggling suppressSnapshot to: ${state}`)
        self.suppressSave = state
      },

      async fetchDocument(type: ApplicationType) {
        const initial = self.firstQuestion
        const request = fetchRoute(type, initial)

        try {
          const response = await request
          self.toggleSuppressSnapshot(true)
          self.setDocument(response)
          self.toggleSuppressSnapshot(false)
        } catch (error) {
          console.error('Failed to fetch document:', error)
        }
      },
      setDocument(response: any) {
        applySnapshot(self, response)
      },
    }
  }
  return types
    .model({ _id: '' })
    .volatile(() => ({
      suppressSave: false,
    }))
    .actions(actions)
}
