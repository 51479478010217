import { get, post, put } from '../helpers/caller'

export const contractor = {
  domain: 'contractor',
  apply() {
    return post(contractor.domain, contractor.apply)
  },
  invitation(id: string) {
    return get(contractor.domain, contractor.invitation, { id })
  },
  invitationSubmit(data: any) {
    return post(contractor.domain, contractor.invitation, data)
  },
  creditApplicationUpload(companyId: string, files: string[]) {
    return post(
      contractor.domain,
      contractor.creditApplicationUpload,

      { companyId, files },
    )
  },
  callDecisionEngine(id: string) {
    return post(contractor.domain, contractor.callDecisionEngine, { id })
  },
  saveOwnerData() {
    return post(contractor.domain, contractor.saveOwnerData)
  },
  resubmit(companyId: string) {
    return post(contractor.domain, contractor.resubmit, { companyId })
  },
  referrals() {
    return get(contractor.domain, contractor.referrals)
  },
  loan(id: string) {
    return get(contractor.domain, contractor.loan, { id })
  },
  loans(lmsId?: string) {
    return get(contractor.domain, contractor.loans, { lmsId })
  },
  paymentPlan(id: string) {
    return get(contractor.domain, contractor.paymentPlan, { id })
  },
  upgradePlan(id: string, planName: string) {
    return post(contractor.domain, contractor.paymentPlan, { planName }, { id })
  },
  makeAgreement(data: {
    invoiceIds?: string[]
    paymentPlan?: any
    agreement_type?: string
  }) {
    return post(contractor.domain, contractor.makeAgreement, data)
  },
  makeIntegrationAgreement(data: {
    paymentPlan?: any
    totalAmount: number
    supplierCompanyId: string
  }) {
    return post(contractor.domain, contractor.makeIntegrationAgreement, data)
  },
  previewPersonalGuarantorAgreement(draftId: string) {
    return get(
      contractor.domain,
      contractor.previewPersonalGuarantorAgreement,
      { draftId },
    )
  },

  previewMasterAgreement() {
    return get(contractor.domain, contractor.previewMasterAgreement)
  },

  submitCreditApplication(data: {
    loanApplicationId: string
    ipAddress: string
  }) {
    return post(contractor.domain, contractor.submitCreditApplication, data)
  },

  submitInHouseCreditApplication(draftId: string, supplierId: string) {
    return post(contractor.domain, contractor.submitInHouseCreditApplication, {
      draftId,
      supplierId,
    })
  },

  getAgreementMetadata(agreement_type: string) {
    return get(contractor.domain, contractor.getAgreementMetadata, {
      agreement_type,
    })
  },

  tryGetMasterAgreement() {
    return get(contractor.domain, contractor.tryGetMasterAgreement)
  },
  hasPersonalGuarantorAgreement() {
    return get(contractor.domain, contractor.hasPersonalGuarantorAgreement)
  },

  downloadPersonalGuarantorAgreements() {
    return get(
      contractor.domain,
      contractor.downloadPersonalGuarantorAgreements,
    )
  },

  viewAgreement(id: string) {
    return get(contractor.domain, contractor.viewAgreement, { id })
  },

  makePayment(data: any) {
    return put(contractor.domain, contractor.makePayment, data)
  },
  loanPayableDetails(id: string) {
    return get(contractor.domain, contractor.loanPayableDetails, { id })
  },
  calculateCardFees(id: string) {
    return get(contractor.domain, contractor.calculateCardFees, {
      paymentMethodId: id,
    })
  },
}
