import { RouteBase } from '../types'
import {
  IBrandingResponse,
  ICheckRequest,
  ICheckResponse,
  IInfoResponse,
  ILoanApplicationResponse,
  IMigrateRequest,
  IMigrateResponse,
  IRemoteConfigResponse,
  ISignupRequest,
  ISignupResponse,
  IWebAuthRequest,
  IWebAuthResponse,
} from './types'
import { IInvoiceDetailsModel, IUserModel } from '../../mst'

export class User extends RouteBase {
  domain = 'user'

  signup(data: ISignupRequest) {
    return this.buildPostRoute<ISignupResponse>(this.signup, data)
  }

  migrateCompany(data: IMigrateRequest) {
    return this.buildPostRoute<IMigrateResponse>(this.migrateCompany, data)
  }

  apply(ipAddress: string) {
    return this.buildPostRoute(this.apply, { ipAddress })
  }

  check(data: ICheckRequest) {
    return this.buildGetRoute<ICheckResponse>(this.check, data)
  }

  info() {
    return this.buildGetRoute<IInfoResponse>(this.info)
  }

  updateInfo(data: IUserModel) {
    return this.buildPostRoute<IInfoResponse>(this.updateInfo, data)
  }

  webAuth(data: IWebAuthRequest) {
    return this.buildPostRoute<IWebAuthResponse>(this.webAuth.name, data)
  }

  remoteConfig() {
    return this.buildGetRoute<IRemoteConfigResponse>(
      this.remoteConfig.name,
      {},
      true,
    )
  }

  loanApplication(invoiceDetails?: IInvoiceDetailsModel, supplierId?: string) {
    return this.buildPostRoute<ILoanApplicationResponse>(
      this.loanApplication.name,
      { invoiceDetails, supplierId },
    )
  }

  handleLoanApplication(
    invoiceDetails?: IInvoiceDetailsModel,
    projectId?: string,
  ) {
    return this.buildPostRoute<ILoanApplicationResponse>(
      this.handleLoanApplication.name,
      { invoiceDetails, projectId },
    )
  }

  branding() {
    return this.buildGetRoute<IBrandingResponse>(this.branding.name, {})
  }
}

export default new User()
